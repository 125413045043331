import React from 'react';
import Colors from '../constants/ColorConstants';

const ErrorIcon = ({
    size = 20,
    color = Colors.icon.default,
    viewBox = '0 0 24 24',
    ...props
}) => (

    <svg
        viewBox="0 0 1024 1024"
        p-id="14759"
        width={size}
        height={size}
        fill='none'
        {...props}
    >
        <path
            d="M469.333333 640h85.333334v85.333333h-85.333334z m0-341.333333h85.333334v256h-85.333334z m42.453334-213.333334C276.053333 85.333333 85.333333 276.266667 85.333333 512s190.72 426.666667 426.453334 426.666667S938.666667 747.733333 938.666667 512 747.52 85.333333 511.786667 85.333333zM512 853.333333c-188.586667 0-341.333333-152.746667-341.333333-341.333333S323.413333 170.666667 512 170.666667s341.333333 152.746667 341.333333 341.333333-152.746667 341.333333-341.333333 341.333333z"
            p-id="14760"
            fill={color}
        ></path>
    </svg>
);

export default ErrorIcon;
