import React, { Component } from 'react';
import { Input } from 'reactstrap';
import ErrorIcon from '../../../icons/ErrorIcon';
import classNames from 'classnames';
import SvgIcon from '../../../components/common/SvgIcon';

const PAYMENT = window.$ && window.$.payment;
// const PAYMENT_FN = window.$ && window.$.fn.payment;
const $ = window.$;
const invald_str_arr = [',', '.', '@', '-', '_', '=', '+', '#', '/', '0', '1','2', '3', '4', '5', '6', '7', '8', '9', '!', '$', '%', '^', '&', '*', '(', ')'];
class PaymentForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            cardNumber: '',
            isCardNumberError: false,
            cardNumberErrorMsg: '',
            cardType: '',

            cardExpiry: '',
            isExpirError: false,

            cardCVC: '',
            isCVCError: false,

            cardholderName: '',
            isCardHolderNameError: false,

            isSubmitting: false,
        }
        this.handleCardNumChange = this.handleCardNumChange.bind(this);
        this.handleCardExpiryChange = this.handleCardExpiryChange.bind(this);
        this.handleCardCVCChange = this.handleCardCVCChange.bind(this);
        this.handleCheckTheCardNumber = this.handleCheckTheCardNumber.bind(this);
        this.handleCheckTheCardExpiry = this.handleCheckTheCardExpiry.bind(this);
        this.handleCheckTheCardCVC = this.handleCheckTheCardCVC.bind(this);
        this.handleHolderNameChange = this.handleHolderNameChange.bind(this);
        this.handleCheckCardHolderName = this.handleCheckCardHolderName.bind(this);
        this.getPaymentFormData = this.getPaymentFormData.bind(this);
        this.checkFormDisabled = this.checkFormDisabled.bind(this);
        this.changeCardPrompt = this.changeCardPrompt.bind(this);
    }

    componentDidMount() {
        const { cardNumber } = this.state;
        if (cardNumber && cardNumber !== '') {
            const cardType = PAYMENT.cardType(cardNumber)?.replaceAll('card', '');
            this.setState({ cardType });
        }

        $('#card_number').payment('formatCardNumber')
        $('#card_expiry').payment('formatCardExpiry')
        $('#card_cvc').payment('formatCardCVC')

        // console.log($('input#card_number').payment('master'));
        // console.log(PAYMENT_FN('5329596042194308'));
        // console.log(PAYMENT.cardType('5329 5960 4219 4308'));
        // console.log(PAYMENT.cardType('35'));
    }

    getPaymentFormData() {
        // const orderNo = cookie.load('order_no', { path: '/' });
        const params = {
            // 'paymentEmail': this.state.email,
            'cardNumber': this.state.cardNumber,
            'cardType': this.state.cardType,
            'cardExpiry': this.state.cardExpiry,
            'cardCvc': this.state.cardCVC,
            'cardName': this.state.cardholderName,
        }
        return params;
    }

    handleCardNumChange(e) {
        const cardNumber = e && e.target.value;
        const cardType = PAYMENT.cardType(cardNumber)?.replaceAll('card', '');
        if (cardType) {
            this.setState({
                cardNumber,
                cardType,
                isCardNumberError: false,
            }, this.checkFormDisabled)
        } else {
            this.setState({
                cardNumber,
                cardType: null,
                isCardNumberError: false,
            }, this.checkFormDisabled);
        }

    }

    handleCheckTheCardNumber(e) {
        const cardNumber = e && e.target.value;
        this.setState({
            cardNumber,
            isCardNumberError: !PAYMENT.validateCardNumber(cardNumber),
            cardNumberErrorMsg: !PAYMENT.validateCardNumber(cardNumber) ? 'Incomplete card number' : ''
        }, this.checkFormDisabled)
    }

    handleCardExpiryChange(e) {
        const cardExpiry = e && e.target.value;
        this.setState({
            cardExpiry,
            isExpirError: false,
        }, this.checkFormDisabled)
    }

    handleCheckTheCardExpiry(e) {
        const cardExpiry = e && e.target.value;
        console.log(cardExpiry)
        const MM = cardExpiry.split('/')[0];
        const YY = cardExpiry.split('/')[1];
        this.setState({
            cardExpiry,
            isExpirError: !PAYMENT.validateCardExpiry(MM, YY),
        }, this.checkFormDisabled)
    }

    handleCardCVCChange(e) {
        const cardCVC = e && e.target.value;
        this.setState({
            cardCVC,
            isCVCError: false,
        }, this.checkFormDisabled)
    }

    handleCheckTheCardCVC(e) {
        const cardCVC = e && e.target.value;
        let { cardType } = this.state;
        cardType = (cardType === 'master' ? cardType + 'card' : cardType);
        this.setState({
            cardCVC,
            isCVCError: !PAYMENT.validateCardCVC(cardCVC, cardType),
        }, this.checkFormDisabled)
    }

    handleHolderNameChange(e) {
        const cardholderName = e && e.target.value;
        this.setState({
            cardholderName,
            isCardHolderNameError: false
        }, this.checkFormDisabled);
    }

    handleCheckCardHolderName(e) {
        const cardholderName = e && e.target.value;
        for (let i = 0; i < invald_str_arr.length; i++) {
            if (cardholderName.indexOf(invald_str_arr[i]) >= 0) {
                this.setState({ isCardHolderNameError: true }, this.checkFormDisabled)    
                break;
            }
        }
        if (cardholderName.trim() === '') {
            this.setState({ isCardHolderNameError: true }, this.checkFormDisabled)
        }
        this.setState({ cardholderName }, this.checkFormDisabled);
    }

    checkFormDisabled() {
        const {cardNumber, isCardNumberError, cardExpiry, isExpirError, cardCVC, isCVCError, cardholderName, isCardHolderNameError} = this.state;
        const {checkPaymentFormStatus} = this.props;
        return checkPaymentFormStatus && checkPaymentFormStatus(cardNumber.trim() === '' || isCardNumberError || cardExpiry.trim() === '' || isExpirError || cardCVC.trim() === '' || isCVCError || cardholderName.trim() === '' || isCardHolderNameError);
    }

    changeCardPrompt() {
        this.setState({
            isCardNumberError: true,
            cardNumberErrorMsg: 'The bank has rejected the transaction. Please change your card number and try again.'
        }, this.checkFormDisabled);
    }

    render() {
        const { cardNumber, cardType, isCardNumberError, cardNumberErrorMsg, cardExpiry, isExpirError, cardCVC, isCVCError, cardholderName, isCardHolderNameError, isSubmitting } = this.state;


        const disabledPayBtn = (cardNumber.trim() === '' || isCardNumberError || cardExpiry.trim() === '' || cardCVC.trim() === '' || isCVCError || cardholderName.trim() === '' || isCardHolderNameError)
        return (
            <div className="payment-page-container tw-w-full payment-form sourceSansPro-font phone:tw-flex phone:tw-justify-center">
                <div className="tw-w-full phone:!tw-shadow-none ">
                    <div className=" phone:tw-w-full">
                        <div className=''>
                            <div className="form-container !tw-mt-0">

                                <div className="form-input-container card-info-form">
                                    <div className='tw-z-[100] tw-relative'>
                                        <div className='tw-text-[12px]'>Card Number *</div>
                                        <Input
                                            type="email"
                                            className={classNames(`tw-h-[49px] tw-rounded-[6px] `, { 'error-hint': isCardNumberError })}
                                            id="card_number"
                                            placeholder="1234 1234 1234 1234"
                                            value={cardNumber}
                                            onChange={this.handleCardNumChange}
                                            onBlur={this.handleCheckTheCardNumber} />
                                        {!isCardNumberError && cardType && cardType !== '' && <SvgIcon name={`${cardType}-card-icon`} className='tw-absolute tw-top-[34px] tw-right-[8px] tw-z-[1000]' />}
                                        {isCardNumberError && <ErrorIcon className="icon error-icon" color="#dc2727" />}
                                        {isCardNumberError && <div className="error-text">{cardNumberErrorMsg}</div>}
                                    </div>
                                    <div className='tw-w-full tw-flex tw-justify-between tw-items-center phone:tw-flex-col tw-mt-[12px]'>
                                        <div className='tw-w-[49%] phone:tw-w-full'>
                                            <div className='tw-text-[12px]'>Expiration *</div>
                                            <Input type="text"
                                                className={classNames('tw-w-full tw-h-[49px] tw-rounded-[6px]', { 'error-hint': isExpirError })}
                                                id="card_expiry"
                                                placeholder="MM / YY"
                                                value={cardExpiry}
                                                onChange={this.handleCardExpiryChange}
                                                onBlur={this.handleCheckTheCardExpiry} />

                                        </div>

                                        <div className='tw-w-[49%] phone:tw-w-full phone:tw-mt-[12px]'>
                                        <div className='tw-text-[12px]'>CVV *</div>
                                            <Input type="number"
                                                className={classNames("tw-w-full tw-h-[49px] tw-rounded-[6px]", { 'error-hint': isCVCError })}
                                                id="card_cvc"
                                                placeholder="CVC"
                                                value={cardCVC}
                                                onChange={this.handleCardCVCChange}
                                                onBlur={this.handleCheckTheCardCVC} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-container tw-mt-[20px]">
                                    <div className="form-input-container">
                                        <div className="form-input-container">
                                            <div className='tw-text-[12px]'>Card Holder</div>
                                            <Input
                                                type="email"
                                                className={classNames('tw-w-full tw-h-[49px] tw-rounded-[6px]', { 'error-hint': isCardHolderNameError })}
                                                id="card_holder_name"
                                                placeholder="Card Holder"
                                                value={cardholderName}
                                                onChange={this.handleHolderNameChange}
                                                onBlur={this.handleCheckCardHolderName}
                                            />
                                            {isCardHolderNameError && <ErrorIcon className="icon error-icon" color="#dc2727" />}
                                            {isCardHolderNameError && <div className="error-text">Please enter the correct cardholder.</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentForm;