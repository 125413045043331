import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PagePathConstants from "../constants/router/PagePathConstants";
import Loading from "../components/common/LoadingIndicator";
import EarthIcon from "../icons/EarthIcon";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Email from "../images/email.svg";
import * as storyActionCreator from "../actions/storyActionCreator";
import * as storyCommentActionCreator from "../actions/storyCommentActionCreator";
import * as DateTimeUtil from "../utils/DateTimeUtil";
import * as TranslationSelectors from "../selectors/TranslationSelectors";
import Model3Img from "../images/tesla-order/model-3.jpg";
import ModelXImg from "../images/tesla-order/model-x.jpg";
import ModelYImg from "../images/tesla-order/model-y.jpg";
import XIcon from "../icons/XIcon";
import { isMobileScreen } from "../utils/ResponsivePageUtils";
import AdHeaderImg from "../images/ad-logo.png";
import { sendCustomEvent } from "../apis/ConfigAPI";
import web_header from "../images/web-header.png";
import TeslaIcon from '../icons/TeslaIcon';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/scss/pagination';
import tracker from '../tracker';
import classNames from 'classnames';

class AdvertisingPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            story: null,
            storyId: 7,
            commentList: [],
            commentTotal: 0,
            showLoading: false,
            showSlidingComponent: false,
        };

        this.routeToTeslaRootPage = this.routeToTeslaRootPage.bind(this);
        this.getStoryInfoById = this.getStoryInfoById.bind(this);
        this.getStoryCommentListById = this.getStoryCommentListById.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    scrollContainerRef = React.createRef();

    componentDidMount() {
        this.getStoryInfoById();
        this.getStoryCommentListById();
        sendCustomEvent("RouteToTheStoryPage");
    }

    getStoryInfoById() {
        const { location, storyActions } = this.props;
        const { storyId } = this.state;
        this.toggleLoading();
        storyActions
            .getStoryInfoById(storyId)
            .then((res) => {
                this.toggleLoading();
                const storyInfo = res;
                this.setState({
                    story: storyInfo,
                });
            })
            .catch((err) => {
                this.toggleLoading();
            });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    getStoryCommentListById() {
        let params = { tieziid: this.state.storyId };
        const { commentActions } = this.props;
        return (
            commentActions &&
            commentActions
                .fetchCommentList(params)
                .then((data) => {
                    if (data.commentList) {
                        const { commentList, total } = data;
                        this.setState({ commentList, commentTotal: total });
                    }
                })
                .catch()
        );
    }

    routeToTeslaRootPage() {
        const { history, location } = this.props;
        tracker.trackNewsPage();

        history &&
            history.push({
                pathname: PagePathConstants.TESLA,
                search: location.search,
            });
    }

    randomDate(index) {
        // 随机生成0-11的数字
        const randomMonthNum = Math.floor(Math.random() * 11);
        // 随机生成1-30数字
        const randomDateNum = Math.ceil(Math.random() * 30);
        // 随机生成1-24 数字
        const randomHourNum = Math.ceil(Math.random() * 24);
        // 随机生成1-60 数字
        const randomMinuteNum = Math.ceil(Math.random() * 60);
        let time;
        if (index < 3) {
            time = moment()
                .subtract(index, "days")
                .hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum);
        } else if (index >= 3 && index < 6) {
            time = moment()
                .subtract(index, "days")
                .hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum);
        } else {
            time = moment()
                .subtract(index < 9 ? index : 8, "days")
                .hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum);
        }
        return DateTimeUtil.getFriendlyTime(time);
    }

    handleScroll() {
        const scrollTop =
            this.scrollContainerRef && this.scrollContainerRef.current.scrollTop;
        if (scrollTop >= 500 && !this.state.showSlidingComponent) {
            this.setState({ showSlidingComponent: true });
        }

        if (scrollTop < 500 && this.state.showSlidingComponent) {
            this.setState({ showSlidingComponent: false });
        }
    }

    render() {
        const { story, commentList, showLoading, showSlidingComponent } = this.state;
        const mockReportTime = DateTimeUtil.getFriendlyTime(moment(new Date()).subtract(10, 'days').subtract(2, 'hours').subtract(30, 'minutes'));
        const teslaImgArr = [Model3Img, ModelXImg, ModelYImg];
        let { translations } = this.props;
        translations = translations && translations['submission_page'];
        return (
            <div className='tw-w-full tw-h-full' >
                {showLoading && <Loading />}
                {showSlidingComponent && <div className='tw-z-[1000001] tw-fixed tw-w-[80%] tw-left-[10%] tw-bottom-[10px] tw-bg-[#d20f27] tw-text-white tw-font-bold tw-cursor-pointer tw-h-[40px] tw-flex tw-items-center tw-justify-center tw-rounded-[6px] hs-ad-apply-btn' onClick={this.routeToTeslaRootPage}>APPLY NOW! JUST 5000 CARS</div>}
                <div className={classNames('tw-w-full tw-h-full ad-bg-container tw-relative tw-flex tw-justify-center tw-overflow-scroll', {'!tw-h-[calc(100%-60px)]' : showSlidingComponent})} onScroll={this.handleScroll} ref={this.scrollContainerRef}>
                    <div className='tw-absolute tw-top-0 tw-left-[50%] tw-ml-[-357px] phone:tw-left-0 phone:tw-ml-[0px] tw-w-[700px] tw-h-[70px] phone:tw-w-full tw-z-[100000] tw-flex tw-items-center tw-px-[16px] tw-bg-gradient-to-b tw-from-black-500'>
                        {/* <img src={AdHeaderImg} className='tw-h-[50px] ' alt='header' /> */}
                        <TeslaIcon size={120} color='white'/>
                        <div className='tw-absolute tw-h-[35px] tw-right-[20px] tw-top-[50%] tw-mt-[-17px] tw-text-[14px] phone:tw-text-[14px] tw-rounded-[5px] tw-px-[15px] tw-py-[7px] tw-bg-[#d20f27] tw-text-white tw-font-bold tw-cursor-pointer hs-ad-apply-btn' onClick={this.routeToTeslaRootPage}>APPLY NOW! JUST 5000 CARS</div>
                    </div>
                    <div className={classNames('tw-w-[700px] phone:tw-w-full tw-text-advertising-text tw-bg-white tw-relative')}>
                        <div className='tw-w-full tw-absolute tw-top-[100px] tw-text-[28px] tw-text-center tw-text-white'>Campaigns / Test Ride Events</div>
                        <div className='tw-pb-0'>
                            {story && story.logo && story.logo !== '' && <img src={story && story.logo} alt='logo-avatar' className='tw-w-full'/>}
                            <div className='hs-story-description tw-px-[24px] tw-pt-[48px] tw-text-[#393c41] tw-text-[14px]' dangerouslySetInnerHTML={{ __html: story && story.description }}></div>

                            <div className='tw-mt-[15px] tw-mx-[20px] hs-comment-container'>
                                <div className='tw-pb-[20px] tw-w-full tw-border-b tw-border-b-[1px] tw-border-[rgba(15, 17, 17, 0.8)]'></div>
                                {commentList && commentList.map((comment, index) => {
                                    const commentTime = this.randomDate(index);
                                    return (<div className='tw-mb-[18px] tw-flex tw-items-start'>
                                        <div className='tw-mt-[4px] tw-w-[32px] tw-h-[32px] tw-rounded-full tw-overflow-hidden tw-flex-shrink-0' style={{ backgroundImage: `url(${comment.logo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                        <div className='tw-ml-[5px]'>
                                            <div className='tw-px-[12px] tw-py-[8px] tw-rounded-[20px] tw-bg-[#F0F2F5]'>
                                                <div className='tw-font-bold tw-text-[12px]'>{comment && comment.nickname}</div>
                                                <div className='tw-text-[12px] tw-mt-[4px]' dangerouslySetInnerHTML={{ __html: comment && comment.description }}></div>
                                            </div>
                                            <div className='tw-mt-[5px] tw-px-[12px] tw-flex tw-items-center tw-text-[12px] tw-text-body-text-grey'>
                                                <div className='tw-flex tw-items-center'>
                                                    <EarthIcon width="16" height="16" color='#737B8B' className="tw-mr-[8px]" />
                                                    <span className=''>{commentTime}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>)
                                })}
                                <div className='tw-w-full tw-text-center tw-border-t tw-border-[rgba(128, 128, 128, 0.7)] tw-text-[12px]'>
                                    <div className='tw-w-full tw-my-[10px]'>Tesla © 2024</div>
                                    <div className='tw-w-full tw-my-[10px]'>Privacy & Legal</div>
                                    <div className='tw-w-full tw-my-[10px]'>News</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        storyActions: bindActionCreators(storyActionCreator, dispatch),
        commentActions: bindActionCreators(storyCommentActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisingPage);
