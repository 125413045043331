import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PagePathConstants from "../constants/router/PagePathConstants";
import RdsBackIcon from "../icons/RdsBackIcon";
import TeslaIcon from "../icons/TeslaIcon";
import debounce from "lodash/debounce";

import TeslaVideo1 from "../images/tesla-ads/tesla-video1.webm";
import TeslaAd1 from "../images/tesla-ads/tesla-ad1-mobile.avif";
import TeslaAd2 from "../images/tesla-ads/tesla-ad2.avif";
import TeslaAd3 from "../images/tesla-ads/tesla-ad3.avif";
import TeslaAd4 from "../images/tesla-ads/tesla-ad4.avif";

import TeslaVideo1Mobile from "../images/tesla-ads/tesla-video1-mobile.mp4";
import TeslaAd1Mobile from "../images/tesla-ads/tesla-ad1-mobile.avif";
import TeslaAd2Mobile from "../images/tesla-ads/tesla-ad2-mobile.avif";
import TeslaAd3Mobile from "../images/tesla-ads/tesla-ad3-mobile.avif";
import TeslaAd4Mobile from "../images/tesla-ads/tesla-ad4-mobile.avif";
import TeslaAd5Mobile from "../images/tesla-ads/tesla-ad5-mobile.avif";
import tracker from "../tracker";
import * as FBUtil from "../utils/FbUtil";
import { isMobileScreen } from "../utils/ResponsivePageUtils";
import { generateRandomString } from "../utils/RandomGeneratorUtils";
import * as fbEventActionCreator from "../actions/fbEventActionCreator";
import { sendCustomEvent } from "../apis/ConfigAPI";

const teslaAdElement = [TeslaVideo1, TeslaAd1, TeslaAd2, TeslaAd3, TeslaAd4];
const teslaAdElementMobile = [
  TeslaVideo1Mobile,
  TeslaAd1Mobile,
  TeslaAd1Mobile,
  TeslaAd2Mobile,
  TeslaAd3Mobile,
  TeslaAd4Mobile,
  TeslaAd5Mobile,
];

class TeslaPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      adOffsetTop: [],
      scrollTop: 0,
      activeAdIndex: 0,
    };

    this.routeToOrderPage = this.routeToOrderPage.bind(this);
    this.displayVideo = this.displayVideo.bind(this);
    this.calcTeslaAdOffsetTop = this.calcTeslaAdOffsetTop.bind(this);
    this.handleScroll = debounce(this.handleScroll.bind(this), 1000);
    this.goBack = this.goBack.bind(this);
  }
  videoRef = React.createRef();
  sliderContainerRef = React.createRef();

  goBack() {
    const { history } = this.props;
    history && history.goBack();
  }

  componentDidMount() {
    this.displayVideo();
    setTimeout(() => {
      this.calcTeslaAdOffsetTop();
    }, 1000);
    sendCustomEvent("RouteToTheVideoPage");
  }

  calcTeslaAdOffsetTop() {
    const teslaAdComponents = document.querySelectorAll(
      ".tesla-advertising-map"
    );
    let offsetArr = [];
    for (let i = 0; i < teslaAdComponents.length; i++) {
      offsetArr.push(teslaAdComponents[i].offsetTop);
    }
    this.setState({ adOffsetTop: offsetArr });
  }

  handleScroll() {
    const sliderScrollTop =
      this.sliderContainerRef.current &&
      this.sliderContainerRef.current.scrollTop;
    if (this.state.adOffsetTop.indexOf(sliderScrollTop) >= 0) {
      this.setState({ scrollTop: sliderScrollTop });
      return;
    }
    const { scrollTop, activeAdIndex, adOffsetTop } = this.state;
    if (sliderScrollTop !== undefined && sliderScrollTop !== null) {
      let newIndex;
      if (scrollTop <= sliderScrollTop) {
        // 向下滚动
        newIndex =
          activeAdIndex + 1 > adOffsetTop.length - 1
            ? activeAdIndex
            : activeAdIndex + 1;
      } else {
        // 向上滚动
        newIndex = activeAdIndex - 1 < 0 ? activeAdIndex : activeAdIndex - 1;
      }

      this.setState(
        { scrollTop: sliderScrollTop, activeAdIndex: newIndex },
        () => {
          const teslaAdComponents = document.querySelectorAll(
            ".tesla-advertising-map"
          );
          teslaAdComponents[newIndex].scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          this.setState({ scrollTop: adOffsetTop[newIndex] });
        }
      );
    }
  }

  async displayVideo() {
    this.videoRef.current.style.width = document.body.clientWidth + "px";
    this.videoRef.current.style.height =
      this.sliderContainerRef.current.clientHeight + "px";
  }

  routeToOrderPage() {
    const { history, location, fbEventActions } = this.props;

    const eventId = generateRandomString(10);
    const eventParams = {
      content_ids: "test-driver",
      content_category: "Car test driver",
      content_name: "Car test driver",
      content_type: "product",
      contents: [
        {
          id: "test-driver",
          title: "Model 3",
          quantity: 1,
        },
      ],
      currency: "JPY",
      value: localStorage.getItem("payment_amount"),
    };
    FBUtil.trackFBEvent("ViewContent", eventParams, eventId);
    sendCustomEvent("ViewContent");
    fbEventActions &&
      fbEventActions.submitFBConversionEvent(
        "ViewContent",
        eventParams,
        null,
        eventId,
        null
      );
    tracker.trackTeslaHomePage();
    history &&
      history.push({
        pathname: PagePathConstants.VEHICLE_MODEL,
        search: location.search,
      });
  }

  render() {
    let headerTitle = `Test drive the remaining ${
        Math.floor(Math.random() * (200 - 20 + 1)) + 20
      } cars today`;
    return (
      <div className="tw-w-full tw-h-full gotham-font tw-bg-black">
        <div className="tw-fixed tw-top-0 tw-left-0 tw-z-[100] tw-w-full tw-h-[53px] phone:tw-h-[92px] tw-flex tw-justify-between tw-items-center tw-text-white hs-tesla-banner tw-flex-wrap">
          <div className="tw-w-[5%] phone:tw-w-[10%] tw-pl-[20px]">
            <RdsBackIcon color="white" onClick={this.goBack} />
          </div>
          <div className="tw-w-[90%] phone:tw-w-[90%] tw-h-full tw-flex phone:tw-flex-col tw-justify-center tw-items-center">
            <div className="tw-text-[17px] phone:tw-text-[12px] tw-font-bold">
              {" "}
              {headerTitle}{" "}
            </div>
            <div className="tw-text-[14px] phone:tw-text-[12px]">
              We welcome your feedback after the test!
            </div>
          </div>
          <div className="tw-w-[5%] phone:tw-w-0"></div>
        </div>
        <div className="tw-w-full tw-h-full phone:tw-h-full">
          <div className="tw-z-[100] tw-w-full tw-h-[120px] tw-fixed tw-top-[53px] phone:tw-top-[92px] tw-left-0 tw-flex tw-justify-center tw-left-0 ">
            <TeslaIcon size={200} color="white" />
          </div>
          <div className="tw-z-[100] tw-w-full tw-h-[120px] phone:tw-h-auto tw-fixed tw-top-[220px] phone:tw-top-[300px] tw-left-0 tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-white">
            {/* <span className='tw-text-[40px] tw-font-[600]'>Model 3</span>
                        <span className='tw-text-[20px] tw-font-[500]'>From $29,740</span> */}

            <div className="tw-text-[34px] tw-font-[600] tw-px-[30px] tw-mt-[30px] tw-text-center">
              {" "}
              4 different cars you can choose from
            </div>
            <div className="tw-text-[30px] tw-font-[600] tw-px-[30px] tw-mt-[10px] tw-text-center">
              Free test drive available for 1 year
            </div>
          </div>
          <div
            className="tw-w-full tw-h-full tw-overflow-scroll tesla-swiper-container tw-relative"
            ref={this.sliderContainerRef}
            onScroll={this.handleScroll}
          >
            {(isMobileScreen() ? teslaAdElementMobile : teslaAdElement).map(
              (url, index) => {
                if (index === 0) {
                  return (
                    <div
                      className="tw-w-full tw-h-full tw-relative tesla-advertising-map"
                      key={url}
                    >
                      <video
                        className="tw-object-cover"
                        ref={this.videoRef}
                        autoPlay
                        webkit-playsinline="true"
                        playsinline="true"
                        src={url}
                        muted="muted"
                        loop
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="tw-w-full tw-h-full tesla-advertising-map"
                      key={url}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${url})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                        alt="tesla-img"
                        className={`tw-w-full tw-h-full`}
                      />
                    </div>
                  );
                }
              }
            )}
          </div>
          <div className="tw-w-full tw-h-[120px] tw-fixed tw-bottom-0 tw-left-0 tw-bg-gradient-to-t tw-from-black tw-to-[rgba(255, 255, 255, 0)] tw-flex tw-flex-col tw-justify-center tw-items-center ">
            <button
              className="tw-px-[20px] tw-h-[40px] tw-bg-white tw-rounded-[10px] tw-font-[600] tw-opacity-80"
              onClick={this.routeToOrderPage}
            >
              {" "}
              Test drive a Tesla for free for one year »
            </button>
            {/* <span className='phone:tw-px-[20px] tw-mt-[20px] tw-text-[12px] tw-text-white'> *Price before incentives and savings is $40,240, excluding taxes and fees. Subject to change. </span> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(TeslaPage);
