import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import * as TranslationAPI from "../apis/TranslationAPI";
import { showErrorGlobalAlert } from './globalAlertActionCreator';
import ActionConstants from "../constants/actions/ActionConstants";

const updateTranslations = (page, resource) => ({
  type: ActionConstants.UPDATE_TRANSLATIONS_RESOURCE,
  payload: { page, resource }
});

export const fetchTranslations = (page) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      TranslationAPI.fetchTranslations()
        .then((res) => {
          if (res.data && res.data.data && res.data.data.langlist && res.data.data.langlist !== '') {
            let langlist = JSON.parse(res.data.data.langlist);
            langlist = langlist['en-US'];


            dispatch(updateTranslations(page, langlist));
            return resolve(langlist);
          }
          return reject('err');

        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          } else {
            dispatch(showErrorGlobalAlert('translations-fetch-error'));
          }
          return reject(err);
        });
    });

  };
};